import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

interface Props {
  siteTitle?: string;
}

const Header: React.FunctionComponent<Props> = ({ siteTitle = "" }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`
          }}
        ></Link>
      </h1>
    </div>
  </header>
);

export default Header;
